import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    show: false,
  },
  mutations: {
    change(state, userInfo) {
      state.userInfo = userInfo;
    },
    close(state, show) {
      state.show = show;
    },
    open(state, show) {
      state.show = show;
    },
  },
  actions: {},
  modules: {},
});
