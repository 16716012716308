<template>
  <div class="container">
    <Header :index="0"></Header>
    <div class="row-banner">
      <img :src="advert.imgUrl" alt="" @click="jump(advert.linkUrl)" />
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in bannerData" :key="index">
          <img
            style="width: 100%; height: 100%; cursor: pointer"
            :src="item.imgUrl"
            @click.stop="jump(item.linkUrl)"
            alt=""
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="col-right">
        <div class="row-top">
          <img src="@/assets/home/icon_header.png" alt="" />
          <div>
            <p>Hi，欢迎来到零钢</p>
            <div class="login_register" @click="login">
              {{ loginFlag ? "个人中心" : "登录 / 注册" }}
            </div>
          </div>
        </div>
        <div class="row-bottom">
          <div class="row-head">
            <div
              class="col-text"
              :class="banner_current === index ? 'col-text-active' : ''"
              v-for="(item, index) in textData"
              :key="index"
              @click="changeText(index)"
            >
              {{ item.text }}
              <div class="col-line" v-if="banner_current === index"></div>
            </div>
          </div>
          <div
            class="ul"
            v-for="(item, index) in textDetail"
            :key="index"
            @click="toDetail(item.id, item.type)"
          >
            <div class="radio"></div>
            <div class="li">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-echarts">
      <div class="timeSlect">
        <el-date-picker
            v-model="echartTime"
            type="month"
            placeholder="选择年月"
            size="mini"
            style="width:100%"
            :clearable="false"
            value-format="yyyy-MM"
            @change="this.echarts_left"
            :editable="false"
        >
        </el-date-picker>
      </div>
      <div id="col-left" ref="echarts_left"></div>
      <div id="col-right" ref="echarts_right"></div>
    </div>
    <div class="row-news">
      <div class="col-left">
        <img :src="qydt.imgUrl" alt="" style="width: 100%; height: 100%" @click="jump(qydt.linkUrl)" />
        <p>企业动态</p>
      </div>
      <div class="col-right">
        <div class="row-head">
          <div class="col-text">行业要闻</div>
          <div class="col-more" @click="showMore">
            更多<i class="el-icon-d-arrow-right"></i>
          </div>
        </div>
        <div
          class="row-list"
          v-for="(item, index) in newData"
          :key="index"
          @click="toDetail(item.id, item.type)"
        >
          <div class="col-hq">行情</div>
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <Concat></Concat>
    <Footer></Footer>
  </div>
</template>
<!--index.vue创建时间：2021/11/1 16:23:43-->
<script>
import Header from "@/components/header";
import Concat from "@/components/concat";
import Footer from "@/components/footer";
let echarts = require("echarts");
export default {
  components: {
    Header,
    Concat,
    Footer,
  },
  data() {
    return {
      loginFlag: false,
      swiperOption: {
        loop: true,
        initialSlide: 1,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        pagination: ".swiper-pagination", //分页器
        paginationClickable: true,
      },
      bannerData: [],
      qydt: "",
      banner_current: 0,
      advert: "",
      echartTime:new Date().getFullYear()+'-'+(new Date().getMonth()+1),
      echartData: {},
      echarts_rightData: {},
      textData: [
        {
          id: 1,
          text: "公告",
        },
        {
          id: 2,
          text: "规则",
        },
        {
          id: 3,
          text: "新手指南",
        },
      ],
      textDetail: [],
      newData: [],
    };
  },
  async mounted() {
    localStorage.setItem("index", "2");
    this.echarts_left();
    this.echarts_right();
    await this.http("/web/advertisement/list", { type: 1 }).then((res) => {
      this.advert = res.data[0];
    });
    await this.http("/web/banner/list", { type: 1 }).then((res) => {
      if (res.data.length < 2) {
        this.swiperOption.autoplay = false;
      }
      this.bannerData = res.data;
    });
    await this.http("/web/notice/page/1", {
      pageNum: 1,
      pageSize: 9999,
    }).then((res) => {
      if (res.code === 200) {
        this.textDetail = res.data.rows;
      }
    });
    await this.http("/web/news/page", {
      pageNum: 1,
      pageSize: 6,
    }).then((res) => {
      if (res.code === 200) {
        this.newData = res.data.rows;
      }
    });
    await this.http("/web/banner/list", { type: 2 }).then((res) => {
      this.qydt = res.data[0];
    });
    if (sessionStorage.getItem("token")) {
      this.loginFlag = true;
    }
  },
  methods: {
    login() {
      if (this.loginFlag) {
        this.$router.push("/main");
      } else {
        this.$router.push("/login");
      }
    },
    jump(url) {
      window.open(url, "_blank");
    },
    showMore() {
      this.$router.push("/moreNews");
    },
    toDetail(id, type) {
      this.$router.push({ path: "/detail", query: { id, type: type || "4" } });
    },
    changeText(index) {
      this.banner_current = index;
      this.http(`/web/notice/page/${this.textData[index].id}`, {
        pageNum: 1,
        pageSize: 9999,
      }).then((res) => {
        if (res.code === 200) {
          this.textDetail = res.data.rows;
        }
      });
    },
    async echarts_left() {
      await this.http("/web/statistics/geteverydaydata",{dateStr:this.echartTime}).then((res) => {
        if (res.code === 200) {
          this.echartData = res.data;
          this.echartTime=res.data.dateStr
        }
      });
      let myChart = echarts.init(this.$refs.echarts_left);
      //配置图表
      let option = {
        title: {
          text: `零钢成长指数`,
        },
        tooltip: {},
        toolbox: {
          right: 50,
          top: -3,
          feature: {
            saveAsImage: {},
          },
        },
        legend: {
          icon: "circle",
          right: "100px",
        },
        xAxis: {
          data: this.echartData.xData,
          axisLabel: {
            textStyle: {
              color: "#3299DB",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#3299DB",
            },
          },
        },
        series: [
          {
            name: "会员",
            type: "line",
            data: this.echartData.yData.userCount,
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#FB0404",
                lineStyle: {
                  color: "#FB0404",
                },
              },
            },
          },
          {
            name: "积分",
            type: "line",
            data: this.echartData.yData.integralCount,
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#F7B609",
                lineStyle: {
                  color: "#F7B609",
                },
              },
            },
          },
          {
            name: "钢币",
            type: "line",
            data: this.echartData.yData.coinCount,
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#3299DB",
                lineStyle: {
                  color: "#3299DB",
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    async echarts_right() {
      await this.http("web/statistics/getcount").then((res) => {
        if (res.code === 200) {
          this.echarts_rightData = res.data;
        }
      });
      let myChart = echarts.init(this.$refs.echarts_right);
      //配置图表
      let option = {
        title: {
          text: "总指数",
        },
        tooltip: {},
        toolbox: {
          right: 50,
          top: -3,
          feature: {
            saveAsImage: {},
          },
        },
        color: ["#FB0404", "#F7B609", "#3299DB"],
        legend: {
          icon: "rect",
          right: "100px",
          data: ["会员", "钢币", "积分"],
        },
        xAxis: {
          data: ["会员", "钢币", "积分"],
          axisLabel: {
            textStyle: {
              color: "#3299DB",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#3299DB",
            },
          },
        },
        series: [
          {
            name: "会员",
            type: "bar",
            stack: "Total",
            data: [
              {
                value: this.echarts_rightData.yData.userCount,
                itemStyle: {
                  color: "#FB0404",
                },
              },
              "-",
              "-",
            ],
          },
          {
            name: "钢币",
            type: "bar",
            stack: "Total",
            data: [
              "-",
              {
                value: this.echarts_rightData.yData.coinCount,
                itemStyle: {
                  color: "#F7B609",
                },
              },
              "-",
            ],
          },
          {
            name: "积分",
            type: "bar",
            stack: "Total",
            data: [
              "-",
              "-",
              {
                value: this.echarts_rightData.yData.integralCount,
                itemStyle: {
                  color: "#3299DB",
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  background: #f6f6f6;
  > .row-banner {
    width: 1200px;
    margin: 16px auto 0;
    display: flex;
    justify-content: space-between;
    > img {
      width: 210px;
      height: 350px;
      cursor: pointer;
    }
    > .swiper {
      width: 760px;
      height: 350px;
      margin-left: 0;
      margin-right: 0;
    }
    > .col-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > .row-top {
        width: 184px;
        height: 98px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        > img {
          width: 48px;
          height: 48px;
          margin-right: 10px;
        }
        > div {
          > p {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
          }
          > .login_register {
            width: 100px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            border: 1px solid #00a0e9;
            border-radius: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            margin-top: 8px;
            cursor: pointer;
          }
        }
      }
      > .row-bottom {
        width: 184px;
        height: 233px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        > .row-head {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          padding-top: 15px;
          background: #fff;
          position: sticky;
          top: 0;
          background: #fff;
          z-index:1;
          > .col-text {
            margin-left: 19px;
            cursor: pointer;
            &:nth-of-type(1) {
              margin-left: 0;
            }
            > .col-line {
              width: 60%;
              margin: 3px auto 0;
              height: 2px;
              background: #3299db;
              border-radius: 1px;
            }
          }
          > .col-text-active {
            color: #3399db;
          }
        }
        > .ul {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 150px;
          margin-top: 15px;
          cursor: pointer;
          > .li {
            font-size: 12px;
            font-weight: 400;
            width: 138px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          > .radio {
            width: 6px;
            height: 6px;
            background: #e5e5e5;
            border-radius: 50%;
          }
        }
      }
    }
  }
  > .row-echarts {
    width: 1200px;
    height: 370px;
    margin: 36px auto 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    >.timeSlect{
      width:150px;
      position: absolute;
      z-index:9;
      top:25px;
      left:150px;
    }
    > #col-left {
      width: 578px;
      height: 318px;
      background: #fff;
      padding: 26px;
      border-radius: 5px;
    }
    > #col-right {
      width: 502px;
      height: 318px;
      background: #fff;
      padding: 26px;
      border-radius: 5px;
    }
  }
  > .row-news {
    width: 1200px;
    height: 280px;
    display: flex;
    justify-content: space-between;
    margin: 50px auto;
    > .col-left {
      width: 582px;
      height: 100%;
      position: relative;
      > p {
        position: absolute;
        bottom: 6px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }
    }
    > .col-right {
      width: 566px;
      height: 262px;
      padding: 0 18px 18px;
      background: #fff;
      border-radius: 5px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      > .row-head {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        position: sticky;
        top: 0;
        background: #fff;
        > .col-text {
          font-size: 16px;
          font-weight: bold;
          height: 100%;
          line-height: 44px;
          border-bottom: 2px solid #3299db;
        }
        > .col-more {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }
      }
      > .row-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        user-select: none;
        margin: 5px 0;
        > .col-hq {
          width: 32px;
          border: 1px solid #fb0404;
          font-size: 12px;
          font-weight: 400;
          color: #fb0404;
          text-align: center;
          white-space: nowrap;
        }
        > p {
          width: 515px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
</style>
