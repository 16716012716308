<template>
  <div class="container">
    <div class="row">
      <div class="col-left">
        <div class="address" @click="address" @mouseleave="leave">
          <img src="@/assets/component/dhdz.png" alt="" />
          成都
          <div class="addContent" v-show="addressFlag">
            <div class="tj">
              推荐城市<span style="color: #fb0404">成都</span>
            </div>
            <div class="zwkf">
              暂未开放
              <div class="city">
                <div class="item">重庆</div>
                <div class="item">绵阳</div>
                <div class="item">雅安</div>
                <div class="item">南充</div>
                <div class="item">遂宁</div>
                <div class="item">泸州</div>
                <div class="item">德阳</div>
                <div class="item">自贡</div>
                <div class="item">广元</div>
                <div class="item">内江</div>
                <div class="item">乐山</div>
                <div class="item">眉山</div>
                <div class="item">达州</div>
                <div class="item">宜宾</div>
                <div class="item">巴中</div>
                <div class="item">资中</div>
                <div class="item">贵州</div>
                <div class="item">昆明</div>
                <div class="item">西安</div>
                <div class="item">拉萨</div>
                <div class="item">兰州</div>
                <div class="item">其他</div>
              </div>
            </div>
          </div>
        </div>
        <p>零钢欢迎您</p>
        <p @click="$router.push('/login')" v-if="!flag">请登录</p>
        <p v-if="!flag" @click="register">免费注册</p>
        <p v-if="flag" @click="$router.push('/main')">个人中心</p>
        <p v-if="flag" @click="login_out">退出</p>
      </div>
      <div class="col-right">
        <p @click="$router.push('/')">零钢首页</p>
      </div>
    </div>
    <div class="row-bg">
      <div class="row">
        <div class="col-logo">
          <img src="@/assets/component/logo.png" alt="" />
          <p>建材供应链全程专业服务平台</p>
        </div>
        <div
          class="col-text"
          :class="active === index ? 'col-active' : ''"
          v-for="(item, index) in textData"
          :key="index"
        >
          <img v-if="item.haveImg" :src="item.url" alt="" />
          <span @click="btn(index)">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<!--header.vue创建时间：2021/11/2 10:36:18-->
<script>
export default {
  props: ["index"],
  data() {
    return {
      flag: false,
      active: 0,
      textData: [
        {
          text: "零钢汇",
          haveImg: false,
          path: "/",
        },
        {
          text: "尾库交易",
          haveImg: false,
          path: "/business",
        },
        {
          text: "优势资源",
          haveImg: false,
          path: "/locklibray",
        },
        {
          text: "急购急售",
          haveImg: true,
          url: require("@/assets/component/remen.png"),
          path: "/salebuy",
        },
        {
          text: "物流运输",
          haveImg: false,
          path: "/logistics",
        },
        {
          text: "项目合作",
          haveImg: false,
          path: "/cooperate",
        },
        {
          text: "零钢汇萃",
          haveImg: true,
          url: require("@/assets/component/luntan.png"),
          path: "/collection",
        },
        {
          text: "钢链万家",
          haveImg: false,
          path: "/more_service",
        },
        {
          text: "线下小店",
          haveImg: false,
          path: "/shop",
        },
        {
          text: "合作伙伴",
          haveImg: false,
          path: "/partner",
        },
      ],
      addressFlag: false,
    };
  },
  mounted() {
    this.active = this.index;
    if (sessionStorage.getItem("token")) {
      this.flag = true;
    }
  },
  methods: {
    btn(index) {
      this.active = index;
      this.$emit("change", this.textData[index]);
      this.$router.push(this.textData[index].path);
    },
    login_out() {
      sessionStorage.clear();
      this.flag = false;
    },
    register() {
      this.$router.push({
        name: "Login",
        params: { type: "register" },
      });
    },
    address() {
      this.addressFlag = true;
    },
    leave() {
      this.addressFlag = false;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  background-color: #f6f6f6 !important;
  user-select: none;
  position: sticky;
  top: 0;
  z-index: 10;
  > .row {
    width: 1200px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    p:hover {
      color: #3399db;
    }
    > .col-left {
      height: 100%;
      display: flex;
      align-items: center;
      color: #666666;
      > .address {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-right: 30px;
        cursor: pointer;
        position: relative;
        > img {
          width: 10px;
          height: 13px;
          margin-right: 5px;
        }
        > .addContent {
          position: absolute;
          top: 36px;
          width: 450px;
          height: 150px;
          background: #fff;
          animation: hideIndex 1s;
          z-index: 9999;
          cursor: default;
          font-size: 13px;
          > .tj {
            color: #999999;
            margin-top: 20px;
            margin-left: 10px;
            > span {
              margin-left: 20px;
            }
          }
          >.zwkf{
            color: #999999;
            margin-top: 15px;
            margin-left: 10px;
            display: flex;
            white-space: nowrap;

            >.city{
              display: flex;
              flex-wrap: wrap;
              margin-left:20px;
              width:350px;
              >.item{
                margin-right: 15px;
                margin-bottom: 10px;
                cursor: pointer;
                &:hover{
                  color:#3399db;
                }
              }
            }
          }
        }
        @keyframes hideIndex {
          0% {
            opacity: 0;
            transform: translateY(-36px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
      > p {
        padding-right: 20px;
        font-size: 12px;
        height: fit-content;
        cursor: pointer;
        &:nth-of-type(1) {
          border-right: solid 1px #666666;
          margin-right: 20px;
          color: #666666;
        }
      }
    }
    > .col-right {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      > p {
        cursor: pointer;
      }
    }
  }
  > .row-bg {
    width: 100%;
    height: 104px;
    background: #1b294e;
    > .row {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      color: #fff;
      > .col-logo {
        display: flex;
        flex-direction: column;
        > img {
          width: 210px;
          height: 30px;
        }
        > p {
          font-size: 14px;
          font-weight: 400;
          margin-top: 13px;
        }
      }
      > .col-text {
        font-size: 16px;
        font-weight: 400;
        position: relative;
        cursor: pointer;
        &:hover {
          color: #f7b609;
        }
        > img {
          max-width: 30px;
          max-height: 19px;
          min-width: 15px;
          min-height: 16px;
          position: absolute;
          top: -18px;
          right: -6px;
        }
      }
      > .col-active {
        color: #f7b609;
      }
    }
  }
}
</style>
