<template>
  <div id="app">
    <van-popup
      v-model="$store.state.show"
      z-index="99"
      get-container="body"
      round
    >
      <div class="popup-container">
        <div class="close" @click="close">
          <van-icon name="cross" size="28px" />
        </div>
        <div class="login" @click="jump">去完善个人信息</div>
        <div class="register">
          <span style="color: #3299db; cursor: pointer">个人信息还没有完善！</span
          >完善后才能发布信息哦~
        </div>
      </div>
    </van-popup>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {

    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  methods: {
    jump() {
      this.$router.push("/main");
      this.$store.commit("close", false);
    },
    close(){
      this.$store.commit("close", false);
    }
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
.el-input.is-disabled .el-input__inner{
  color:#333 !important;
}
.el-form-item__label {
  text-align: justify !important;
  text-align-last: justify !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333333 !important;
}
.el-input-number .el-input__inner {
  text-align: left !important;
}
.swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  background: rgba(255, 255, 255, 0.8) !important;
}
.swiper-pagination-bullet-active {
  background: #fff !important;
}
//设置了默认左边框为白色
.el-menu-item {
  border-left: #fff solid 6px;
}
//设置鼠标悬停时el-submenu的样式
.el-menu-item:hover {
  border-left: #33a2ef solid 6px !important;
  background-color: #e2eff9 !important;
  color: #38b2ff !important;
  i {
    color: #38b2ff;
  }
}
.el-menu-item.is-active {
  border-left: #33a2ef solid 6px !important;
  background-color: #e2eff9 !important;
  color: #38b2ff !important;
  i {
    color: #38b2ff;
  }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009688 !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff !important;
}
.el-pagination.is-background .el-pager li:hover {
  color: #009688 !important;
}
.el-pagination__total {
  margin-left: 15px;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgba(76, 39, 199, 0.5),
    rgba(255, 148, 91, 0.8)
  );
  -webkit-box-shadow: rgba(161, 0, 255, 0.2);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: linear-gradient(
    to bottom,
    rgba(76, 39, 199, 0.5),
    rgba(255, 148, 91, 0.8)
  );
}
.popup-container {
  width: 710px;
  height: 813px;
  background: url("./assets/home/popup_bg.png") no-repeat;
  background-size: 100% 100%;
  > .close {
    color: rgba(255, 255, 255, 0.7);
    position: absolute;
    right: 28px;
    top: 19px;
    cursor: pointer;
  }
  > .login {
    width: 400px;
    height: 70px;
    background: linear-gradient(90deg, #3299db, #46a4e1);
    border-radius: 35px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 70px;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  > .register {
    white-space: nowrap;
    font-size: 20px;
    font-weight: 400;
    color: #999999;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
