import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/moreNews",
    name: "MoreNews",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/MoreNews/index.vue"),
  },
  {
    path: "/article",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Article/index.vue"),
  },
  {
    path: "/detail",
    name: "Detail",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Detail/index.vue"),
  },
  {
    path: "/main",
    name: "",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/views/Main/index.vue"),
    children: [
      {
        path: "",
        name: "Main",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/information/index.vue"
          ),
      },
      {
        path: "property",
        name: "property",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/property/index.vue"
          ),
      },
      {
        path: "member",
        name: "member",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/member/index.vue"
          ),
      },
      {
        path: "authentication",
        name: "Authentication",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/authentication/index.vue"
          ),
      },
      {
        path: "logout",
        name: "logout",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/logout/index.vue"
          ),
      },
      {
        path: "business",
        name: "Mainbusiness",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/business/index.vue"
          ),
      },
      {
        path: "locklibray",
        name: "locklibray",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/locklibray/index.vue"
          ),
      },
      {
        path: "salebuy",
        name: "salebuy",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/salebuy/index.vue"
          ),
      },
      {
        path: "logistics",
        name: "logistics",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/logistics/index.vue"
          ),
      },
      {
        path: "cooperate",
        name: "cooperate",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/cooperate/index.vue"
          ),
      },
      {
        path: "collection",
        name: "collection",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/collection/index.vue"
          ),
      },
      {
        path: "more_service",
        name: "more_service",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/Main/more_service/index.vue"
          ),
      },
    ],
  },
  {
    path: "/business",
    name: "Business",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Business/index.vue"),
  },
  {
    path: "/locklibray",
    name: "Locklibray",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/LockLibrary/index.vue"),
  },
  {
    path: "/salebuy",
    name: "Salebuy",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/SaleBuy/index.vue"),
  },
  {
    path: "/logistics",
    name: "Logistics",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Logistics/index.vue"),
  },
  {
    path: "/cooperate",
    name: "Cooperate",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Cooperate/index.vue"),
  },
  {
    path: "/collection",
    name: "Collection",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Collection/index.vue"),
  },
  {
    path: "/detailcollection",
    name: "DetailCollection",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "@/views/DetailCollection/index.vue"
      ),
  },
  {
    path: "/more_service",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "more_service" */ "@/views/MoreService/index.vue"
      ),
    children: [
      {
        path: "",
        name: "more_service_home",
        component: () =>
          import(
            /* webpackChunkName: "more_service" */ "@/views/MoreService/home/index.vue"
          ),
      },
      {
        path: "lifeservelist",
        name: "Lifeservelist",
        component: () =>
          import(
            /* webpackChunkName: "more_service" */ "@/views/MoreService/lifeservelist/index.vue"
          ),
      },
      {
        path: "houseservelist",
        name: "Houseservelist",
        component: () =>
          import(
            /* webpackChunkName: "more_service" */ "@/views/MoreService/houseservelist/index.vue"
          ),
      },
      {
        path: "jobservelist",
        name: "Jobservelist",
        component: () =>
          import(
            /* webpackChunkName: "more_service" */ "@/views/MoreService/jobservelist/index.vue"
          ),
      },
      {
        path: "recruitservelist",
        name: "Recruitservelist",
        component: () =>
          import(
            /* webpackChunkName: "more_service" */ "@/views/MoreService/recruitservelist/index.vue"
          ),
      },
      {
        path: "materialservelist",
        name: "Materialservelist",
        component: () =>
          import(
            /* webpackChunkName: "more_service" */ "@/views/MoreService/materialservelist/index.vue"
          ),
      },
    ],
  },
  {
    path: "/shop",
    name: "Shop",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Shop/index.vue"),
  },
  {
    path: "/partner",
    name: "Partner",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Partner/index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
