<template>
  <div class="container">
    <div>
      <p><span v-for="(item,index) in list" :key="index" @click="open(item.id,item.contentType)">{{item.remark}} <template v-if="list.length-1!==index">| </template></span></p>
      <p>平台法律顾问: 四川安托律师事务所 电话: 15881115695（代主任）</p>
      <p>© 2021 mylinggang.com, All Rights Reserved.</p>
      <p>四川零钢汇电子商务有限公司 版权所有 蜀ICP备18026701号-3</p>
      <p @click="jump" style="cursor: pointer"><img src="http://www.beian.gov.cn/img/new/gongan.png" alt="">川公网安备 51011302000142号</p>
    </div>
    <el-dialog
        :title="ruleTitle"
        :show-close="false"
        :visible.sync="ruleShow"
        width="35%"
    >
      <div v-html="ruleContent"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ruleShow=false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<!--footer.vue创建时间：2021/11/2 10:37:28-->
<script>
export default {
  data() {
    return {
      list:[],
      ruleTitle:"",
      ruleContent:"",
      ruleShow:false
    };
  },
  mounted() {
    this.http('/web/introduce/listbytype/4').then(res=>{
      this.list=res.data
    })
  },
  methods: {
    jump(){
      location.href="http://www.beian.gov.cn"
    },
    open(id,type){
      if(type===2){
        this.http(`/web/introduce/getbyid/${id}`).then(res=>{
          window.open(res.data.contentStr)
        })
      }else {
        this.http(`/web/introduce/getbyid/${id}`).then(res => {
          this.ruleShow=true;
          this.ruleTitle=res.data.remark;
          this.ruleContent=res.data.contentStr
        })
      }
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 201px;
  background: #1c2b40 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #a8b3c4;
    line-height: 20px;
    >p{
      display: flex;
      align-items: center;
      justify-content: center;
      >span{
        cursor: pointer;
      }
    }
  }
}
</style>
