import axios from "axios";
import { baseUrl } from "./config";
import qs from "qs";
import { Message } from "element-ui";

axios.defaults.headers["Authorization"] = sessionStorage.getItem("token"); //设置请求头
axios.defaults.timeout = 10000;
axios.defaults.crossDomain = true; //请求携带额外数据(不包含cookie)
axios.defaults.baseURL = baseUrl; //请求的api

//防止重复请求
const pending = [];
const CancelToken = axios.CancelToken;
const cancelPending = (config) => {
  pending.forEach((item, index) => {
    if (config) {
      if (item.UrlPath === config.url) {
        item.Cancel(); //取消请求
        pending.splice(index, 1); //移除当前请求记录
      }
    } else {
      item.Cancel(); //取消请求
      pending.splice(index, 1); // 移除当前请求记录
    }
  });
};

//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    cancelPending(config);
    config.cancelToken = new CancelToken((res) => {
      pending.push({ UrlPath: config.url, Cancel: res });
    });
    if (config.method === "get") {
      config.params = {
        ...config.params,
      };
    } else {
      config.data = qs.parse({
        ...config.data,
      });
      config.params = null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//http response 拦截器
axios.interceptors.response.use(
  (response) => {
    cancelPending(response.config);
    if (response.data.code === 4001) {
      Message.error("登录失效或身份验证异常，请重新登录");
      sessionStorage.clear();
      let url = location.href.split("#");
      location.href = url[0] + "#/login";
      return false;
    }
    if (response.data.code === 200) {
      return response;
    } else {
      Message.error(response.data.msg);
      return false;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

//发送请求
export function http(path, data, method) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: path,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      data,
      params: data,
      timeout: 6000,
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export default http;
