<template>
  <div class="container">
    <div class="col-rule">
      <p class="PTitle">平台规则</p>
      <p v-for="(item, index) in ptgz" :key="index" @click="toDetail(item.id)">{{ item.remark }}</p>
    </div>
    <div class="vertical"></div>
    <div class="col-rule">
      <p class="PTitle">常见问题</p>
      <p v-for="(item, index) in cjwt" :key="index" @click="toDetail(item.id)">{{ item.remark }}</p>
    </div>
    <div class="vertical"></div>
    <div class="kfdh">
      <p>
        客服电话<span>{{ info.workTime }}</span>
      </p>
      <p>{{ info.phoneNumber }}</p>
      <div>立即咨询</div>
    </div>
    <div class="vertical"></div>
    <div class="wechat">
      <span>官方公众号</span>
      <img :src="info.wechatQrCodeUrl" alt="" />
    </div>
  </div>
</template>
<!--concat.vue创建时间：2021/11/2 10:37:19-->
<script>
export default {
  data() {
    return {
      ptgz: [],
      cjwt: [],
      info: {},
    };
  },
  async mounted() {
    this.ptgz = (await this.http(`/web/introduce/listbytype/1`)).data;
    this.cjwt = (await this.http(`/web/introduce/listbytype/2`)).data;
    this.info = (await this.http("/web/customerservice/getinfo")).data;
  },
  methods: {
    toDetail(id){
      this.$router.push({
        path:'/article',
        query:{id}
      })
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 1200px !important;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  background-color: #f6f6f6;
  padding: 55px 0;
  > .col-rule {
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      cursor: pointer;
    }
    .PTitle {
      font-size: 20px;
      font-weight: 400;
      color: #3299db;
      cursor: text;
    }
  }
  > .kfdh {
    > p {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 40px;

      > span {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-left: 10px;
      }
    }
    > div {
      width: 183px;
      height: 40px;
      background: #0258ed;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
  > .wechat {
    display: flex;
    align-items: center;
    > span {
      display: block;
      width: 20px;
      font-size: 16px;
      font-weight: 300;
      color: #333333;
      margin-right: 10px;
    }
    > img {
      width: 117px;
      height: 117px;
    }
  }
  > .vertical {
    width: 1px;
    height: 120px;
    background: #bbbbbb;
  }
}
</style>
